var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "render-question margin-15-top" }, [
    !_vm.question.is_event_question
      ? _c("div", { staticStyle: { "font-weight": "bold" } }, [
          _vm.showReorderMark
            ? _c("i", {
                staticClass: "fas fa-exchange fa-rotate-90 color-blue",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", {
            staticClass: "render-question__question-text",
            domProps: { innerHTML: _vm._s(_vm.questionText) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.question.additional
      ? _c(
          "p",
          {
            staticStyle: { "font-style": "italic" },
            domProps: { innerHTML: _vm._s(_vm.questionAdditional) },
          },
          [_vm._v(_vm._s(_vm.question.additional))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.editMode && !_vm.question.with_possible_answers
      ? _c("div", [
          _vm.question.answer_type === "date"
            ? _c(
                "form",
                { staticClass: "form" },
                [
                  _c(
                    "datepicker",
                    {
                      ref: "date-picker",
                      attrs: {
                        highlighted: _vm.highlightedDates,
                        format: _vm.customFormatter,
                        "input-class": "create-event-date-picker",
                        "bootstrap-styling": true,
                        typeable: false,
                      },
                      nativeOn: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "delete",
                                [8, 46],
                                $event.key,
                                ["Backspace", "Delete", "Del"]
                              )
                            )
                              return null
                            _vm.answerText = ""
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "backspace",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            _vm.answerText = ""
                          },
                        ],
                      },
                      model: {
                        value: _vm.answerText,
                        callback: function ($$v) {
                          _vm.answerText = $$v
                        },
                        expression: "answerText",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "input-group-btn",
                          attrs: { slot: "afterDateInput" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.toggleDateCalendar.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          slot: "afterDateInput",
                        },
                        [
                          _c(
                            "btn",
                            {
                              staticClass:
                                "dropdown-toggle create-event-date-picker",
                            },
                            [
                              _c("i", {
                                staticClass: "glyphicon glyphicon-calendar",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.question.answer_type === "time"
            ? _c("form", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "select",
                      {
                        staticClass: "form-control inline-block",
                        domProps: { value: _vm.answerText.split(":")[0] },
                        on: { input: _vm.onStartHourInput },
                      },
                      _vm._l(_vm.startHours, function (hour) {
                        return _c(
                          "option",
                          { domProps: { value: hour.value } },
                          [_vm._v(_vm._s(hour.label))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "select",
                      {
                        staticClass: "form-control inline-block",
                        domProps: { value: _vm.answerText.split(":")[1] },
                        on: { input: _vm.onStartMinuteInput },
                      },
                      _vm._l(_vm.startMinutes, function (minute) {
                        return _c("option", { domProps: { value: minute } }, [
                          _vm._v(_vm._s(minute)),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ])
            : _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.answerText,
                    expression: "answerText",
                  },
                ],
                staticClass: "form-control",
                attrs: { rows: "3", autofocus: "" },
                domProps: { value: _vm.answerText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.answerText = $event.target.value
                  },
                },
              }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editMode && _vm.question.with_possible_answers
      ? _c("div", [
          _vm.question.allow_multiple_select
            ? _c(
                "div",
                _vm._l(_vm.possibleAnswers, function (possibleAnswer) {
                  return _c("div", { staticClass: "checkbox" }, [
                    possibleAnswer.text
                      ? _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.checkedAnswers,
                                expression: "checkedAnswers",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: possibleAnswer.text,
                              checked: Array.isArray(_vm.checkedAnswers)
                                ? _vm._i(
                                    _vm.checkedAnswers,
                                    possibleAnswer.text
                                  ) > -1
                                : _vm.checkedAnswers,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.checkedAnswers,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = possibleAnswer.text,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.checkedAnswers = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.checkedAnswers = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.checkedAnswers = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(possibleAnswer.text) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    possibleAnswer.sub_answers &&
                    possibleAnswer.sub_answers.length &&
                    _vm.checkedAnswers.includes(possibleAnswer.text)
                      ? _c(
                          "div",
                          _vm._l(
                            possibleAnswer.sub_answers,
                            function (subAnswer) {
                              return _c(
                                "div",
                                {
                                  staticClass: "margin-5-left side-padding-15",
                                },
                                [
                                  subAnswer.text
                                    ? _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.checkedSubAnswers,
                                              expression: "checkedSubAnswers",
                                            },
                                          ],
                                          attrs: {
                                            name: "optionsRadiosSub",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            value: `${possibleAnswer.text}${_vm.SUB_ANSWER_PREFIX_SEPARATOR}${subAnswer.text}`,
                                            checked: Array.isArray(
                                              _vm.checkedSubAnswers
                                            )
                                              ? _vm._i(
                                                  _vm.checkedSubAnswers,
                                                  `${possibleAnswer.text}${_vm.SUB_ANSWER_PREFIX_SEPARATOR}${subAnswer.text}`
                                                ) > -1
                                              : _vm.checkedSubAnswers,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.checkedSubAnswers,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = `${possibleAnswer.text}${_vm.SUB_ANSWER_PREFIX_SEPARATOR}${subAnswer.text}`,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.checkedSubAnswers =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.checkedSubAnswers = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.checkedSubAnswers = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(subAnswer.text) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ])
                }),
                0
              )
            : _c(
                "div",
                _vm._l(_vm.possibleAnswers, function (possible_answer) {
                  return _c("div", { staticClass: "radio" }, [
                    possible_answer.text
                      ? _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedAnswer,
                                expression: "selectedAnswer",
                              },
                            ],
                            attrs: { name: "optionsRadios", type: "radio" },
                            domProps: {
                              value: possible_answer.text,
                              checked: _vm._q(
                                _vm.selectedAnswer,
                                possible_answer.text
                              ),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedAnswer = possible_answer.text
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(possible_answer.text) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
          _vm._v(" "),
          _vm.question.allow_comment
            ? _c("span", [_vm._v("Comment:")])
            : _vm._e(),
          _vm._v(" "),
          _vm.question.allow_comment
            ? _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.answerComment,
                    expression: "answerComment",
                  },
                ],
                staticClass: "form-control",
                attrs: { rows: "3" },
                domProps: { value: _vm.answerComment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.answerComment = $event.target.value
                  },
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.attachments.length
      ? _c(
          "div",
          { staticClass: "margin-15-bottom" },
          [
            _c("div", [_vm._v("Attachments:")]),
            _vm._v(" "),
            _vm._l(_vm.attachments, function (attachment) {
              return _c("media-preview", {
                key: attachment.id,
                staticStyle: {
                  display: "inline-block",
                  "margin-right": "5px",
                  "margin-bottom": "5px",
                },
                attrs: { item: attachment, "allow-delete": "" },
                on: {
                  onDelete: function ($event) {
                    return _vm.deleteAttachment(attachment)
                  },
                },
              })
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {},
      [
        _vm.linkedTasks.length
          ? _c("div", [
              _vm._v(
                "Linked\n            " +
                  _vm._s(_vm._f("customTerm")("Tasks")) +
                  ":\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(Object.keys(_vm.groupedLinkedTasks), function (goalId, i) {
          return _c(
            "span",
            { key: goalId },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.groupedLinkedTasks[goalId].name)),
              ]),
              _vm._v(":\n            "),
              _vm._l(_vm.groupedLinkedTasks[goalId].tasks, function (task, j) {
                return [
                  _c("strong", [_vm._v(_vm._s(task.name))]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-trash-o pointer color-red",
                    attrs: { role: "button", tabindex: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.unlinkTask(task)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.unlinkTask(task)
                      },
                    },
                  }),
                  j !== _vm.groupedLinkedTasks[goalId].tasks.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.allowAttachments && _vm.editMode
      ? _c("div", { staticClass: "margin-15-top" }, [
          _c("span", { staticStyle: { "margin-right": "5px" } }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: { click: _vm.addAttachment },
              },
              [_vm._m(0)]
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-right": "5px" } }, [
            _c(
              "a",
              { attrs: { href: "javascript:;" }, on: { click: _vm.linkTask } },
              [
                _c("i", { staticClass: "fa fa-bullseye-arrow" }),
                _vm._v(
                  " + Link " +
                    _vm._s(_vm._f("customTerm")("Goal")) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editMode && (_vm.showSaveButton || _vm.showSaveAlarm)
      ? _c("div", { staticClass: "margin-15-top" }, [
          _vm.showSaveAlarm
            ? _c("div", [
                _c("span", { staticClass: "color-red" }, [
                  _vm._v(
                    "Note: Once you save an entry here a copy will be kept for audit purposes even if deleted from the Diary"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSaveButton
            ? _c(
                "span",
                {
                  staticClass: "render-question__save-button",
                  class: {
                    "render-question__save-button_disabled": !_vm.allowSave,
                  },
                  attrs: {
                    tabindex: "0",
                    role: "button",
                    "aria-label": "Save Answer",
                  },
                  on: { click: _vm.saveAnswer },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-save",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Save\n        "),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fa fa-paperclip" }),
      _vm._v(" + Add Attachment"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }