var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-folder-button",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "no-text-on-mobile": _vm.noTextOnMobile,
            text: _vm.editIconText,
            "icon-class": _vm.editIconClass,
            "icon-prefix": "far",
            "aria-label": "Edit Mode",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.toggleEditMode.apply(null, arguments)
            },
          },
        },
        "vue-folder-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.editMode
        ? _c(
            "span",
            {
              staticClass: "fa-stack",
              staticStyle: { width: "32px" },
              attrs: { slot: "icon" },
              slot: "icon",
            },
            [
              _c("i", {
                staticClass: "far fa-pencil fa-stack-1x",
                staticStyle: { position: "absolute" },
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "far fa-slash fa-stack-1x fa-inverse",
                staticStyle: { position: "absolute" },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }