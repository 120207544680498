<template>
    <vue-folder-button
            :no-text-on-mobile="noTextOnMobile"
            @click.native="toggleEditMode"
            :text="editIconText"
            :icon-class="editIconClass"
            icon-prefix="far"
            aria-label="Edit Mode"

            v-bind="$attrs"
            v-on="$listeners"
    >
        <span
                v-if="editMode"
                slot="icon"
                class="fa-stack"
                style="width: 32px;"
        >
            <i class="far fa-pencil fa-stack-1x" style="position: absolute"/>
            <i class="far fa-slash fa-stack-1x fa-inverse" style="position: absolute"/>
        </span>
    </vue-folder-button>
</template>

<script>
    export default {
        name: 'FolderButtonEditToggle',
        emits: ['toggleEditMode'],
        props: {
            editMode: {
                type: Boolean,
                required: true,
            },
            noTextOnMobile: {
                type: Boolean,
                default: false,
            },
            iconText: {
                type: String,
                required: false,
            },
        },
        computed: {
            editIconClass() {
                return this.editMode ? 'fa-eye' : 'fa-pencil';
            },
            editIconText() {
                if (this.iconText) {
                    return this.iconText;
                }
                return this.editMode ? 'Finish Editing' : 'Edit';
            },
        },
        methods: {
            toggleEditMode() {
                this.$emit('toggleEditMode', !this.editMode);
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
